import { AddressData, CommAddress, ContactData, FormattedText, ProjectData, ProjectText, } from "@easterngraphics/wcf/modules/eaiws/project";
import { isNotNullOrEmpty } from "@easterngraphics/wcf/modules/utils/string";
import axios from "axios";
import { envi } from "./Helpers";
export async function setAccountData(accountId) {
    const accountRes = await axios.get(envi(new URL(window.location.href).toString()) +
        "Account?accountId=" +
        accountId);
    const account = accountRes.data;
    return account;
}
export async function setContactData(contactId) {
    try {
        const contactRes = await axios.get(envi(new URL(window.location.href).toString()) +
            "Contact?contactId=" +
            contactId);
        const contact = contactRes.data;
        return contact;
    }
    catch (error) {
        const contact = {
            id: "",
            firstName: "",
            lastName: "",
            telephone1: "",
            jobTitle: "",
            emailAddress1: "",
        };
        return contact;
    }
}
/**
 * Fill header data with example project information.
 */
export function setExampleProjectData(pSession, quote, account) {
    const tData = new ProjectData();
    tData.projectNumber = quote.quoteNumber;
    tData.projectName = quote.name;
    tData.company = account.name;
    tData.customerNumber = account.customerNumber;
    tData.projectTexts = [
        createProjectText("Kjære Kunde,\n\n" +
            "Vi setter stor pris på muligheten til å gi dere et tilbud, og takker for tilliten. Vårt mål er å levere kvalitetsprodukter og tjenester som oppfyller deres behov på en effektiv og kostnadsgunstig måte.\n\n" +
            "Nedenfor følger et sammendrag over vilkårene knyttet til dette tilbudet. For ytterligere informasjon, vennligst se våre Salgs- og leveringsbetingelser tilgjengelig på våre nettsider.\n\n" +
            "Vilkår og betingelser:\n" +
            "Alle priser er nettopriser eks. mva. Prisene er basert på en samlet bestilling i henhold til tilbud. Reduksjon i antall eller mengde i totaltilbudet, eller den enkelte varelinje, kan ha konsekvenser for prisene.\n" +
            "Leveringsomkostninger og miljøgebyr tilkommer med hhv. 9 % og 1,5% av ordrens totale nettobeløp. Dersom ordrens nettoverdi er under 20.000,- tilkommer et minimumsbeløp for leveringsomkostninger på NOK 1.980 eks. mva. Tillegget for miljøgebyr avhenger av ordrens nettoverdi. For ordrer med en nettoverdi som overstiger NOK 1.000.000 så er tillegget satt til 1%, for ordre med en lavere nettoverdi så gjelder satsen 1,5%.\n\n" +
            "Tilbudet er gyldig til og med 30 dager fra tilbudsdato. Tilbudet settes først i bestilling fra tidspunkt det foreligger en skriftlig ordrebekreftelse fra kunde.\n\n" +
            "Forutsetninger tilknyttet klargjøring av lokaler mm. er inkludert i våre Salgs- og leveringsbetingelser. Dette gjelder også vilkår for eventuelle tilleggsarbeider og andre forhold knyttet til levering.\n\n" +
            "Ytterligere informasjon finnes i våre Salgs- og leveringsbetingelser tilgjengelig på vår nettside\n" +
            "www.holmrisb8.com", "HeaderText"),
    ];
    return pSession.project.setProjectData(tData);
}
/**
 * Fill header data with example customer information.
 *
 *
 */
export async function setExampleCustomerAddress(pSession, quote, account, contact) {
    const tPhoneCommAddr = new CommAddress();
    tPhoneCommAddr.scope = "Business";
    tPhoneCommAddr.type = "Phone";
    tPhoneCommAddr.value = contact.telephone1;
    const tEmailCommAddr = new CommAddress();
    tEmailCommAddr.scope = "Business";
    tEmailCommAddr.type = "EMail";
    tEmailCommAddr.value = contact.emailAddress1;
    const tContact = new ContactData();
    tContact.contactType = "Sale";
    tContact.firstName = contact.firstName;
    tContact.lastName = contact.lastName;
    tContact.title = contact.jobTitle;
    tContact.commAddresses = [tPhoneCommAddr, tEmailCommAddr];
    const tAddress = new AddressData();
    tAddress.addressType = "SoldTo";
    tAddress.name1 = account.name;
    tAddress.street = account.address1Line1;
    tAddress.postalCode = account.address1PostalCode;
    tAddress.location = account.address1City;
    tAddress.countryCode = account.address1Country;
    tAddress.contacts = [tContact];
    const sAddress = new AddressData();
    sAddress.addressType = "ShipTo";
    sAddress.name1 = account.name;
    sAddress.street = quote.shipToLine1;
    sAddress.postalCode = quote.shipToPostalCode;
    sAddress.location = quote.shipToCity;
    sAddress.countryCode = quote.shipToCountry;
    const bAddress = new AddressData();
    bAddress.addressType = "BillTo";
    bAddress.name1 = account.name;
    bAddress.street = quote.billToLine1;
    bAddress.postalCode = quote.billToPostalCode;
    bAddress.location = quote.billToCity;
    bAddress.countryCode = quote.shipToCountry;
    pSession.project.setAddressData(bAddress);
    pSession.project.setAddressData(sAddress);
    return pSession.project.setAddressData(tAddress);
}
/**
 * Helper function to create an instance of `egrWcf.eaiws.project.ProjectText` with a given text as value.
 */
function createProjectText(pText, pType) {
    const tFormattedText = new FormattedText();
    tFormattedText.format = "JSON";
    tFormattedText.language = "und"; //use undefined language
    tFormattedText.value = plainTextToEaiwsJson(pText);
    const tProjectText = new ProjectText();
    tProjectText.type = pType;
    tProjectText.texts = [tFormattedText];
    return tProjectText;
}
/**
 * Helper function to convert a plain text to the EAIWS JSON text format.
 */
function plainTextToEaiwsJson(pText) {
    const tLines = pText.trim().split("\n");
    const tParagraphContent = new Array();
    for (let i = 0; i < tLines.length; ++i) {
        let tLine = tLines[i];
        if (isNotNullOrEmpty(tLine)) {
            //EAIWS will remove consecutive spaces, replace every second space with a "non breaking space" to avoid this
            tLine = tLine.replaceAll("  ", " \u00A0");
            tParagraphContent.push(tLine);
        }
        if (i < tLines.length - 1) {
            tParagraphContent.push(["br"]);
        }
    }
    const tParagraph = ["p", null, tParagraphContent];
    const tEaiwsText = ["iml", null, [tParagraph]];
    return JSON.stringify(tEaiwsText);
}
