import { ajax } from "@easterngraphics/wcf/modules/utils/async";
import { joinPath } from "@easterngraphics/wcf/modules/utils/string";
import { COLUMN_EXT_ITEM_NUMBER, COLUMN_ITEM_IMAGE, PRICING_PROCEDURE_NAME, REPORT_TEMPLATES, } from "../Config";
/**
 * This function saves the whole project/session as an OBK file.
 */
export async function saveOBK(pSession) {
    //get OBK url
    const tUrl = await pSession.session.saveSession(null);
    return tUrl;
}
/**
 * This function saves all items as an OBX file.
 */
export async function saveOBX(pSession) {
    //get all basket items
    const tItems = await pSession.basket.getAllItems();
    console.log(tItems);
    //get url of OBX
    const tItemIdArray = tItems.map((item) => item.itemId);
    const tUrl = await pSession.basket.copy(tItemIdArray);
    console.log(tUrl);
    return tUrl;
}
/**
 * This function generates and save a pdf report.
 */
export async function savePDF(pSession) {
    //use EAIWS reporter plugin to generate a PDF
    const tResult = await ajax("POST", joinPath(pSession.baseUrl, `/EAIWS/plugins/Reporter/template/${REPORT_TEMPLATES}/v1/generate`), {
        sessionId: pSession.sessionId,
        calculationScheme: PRICING_PROCEDURE_NAME,
        preferredImageColumn: COLUMN_ITEM_IMAGE,
        externalRefColumn: COLUMN_EXT_ITEM_NUMBER,
    }, { dataType: "json" });
    return tResult.url;
}
