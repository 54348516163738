////////
//// integration example configration
/** url of the basket */
export const BASKET_URL = "https://integration.basket.pcon-solutions.com/v2.11/?mode=integration";
/** Gatekeeper id used for EAIWS session creation. replace with your gatekeeper ID */
export const GATEKEEPER_ID = "6645a1ce48036";
/** Pricing procedure to use for pCon.basket. change this if you have a custom one */
export const PRICING_PROCEDURE_NAME = "STDB2B_HOLMRIS";
/** Report template which will be used for reporting. change this if you have a custom one */
export const REPORT_TEMPLATES = [
    "basket-standard-pro-fop",
    "basket-standard-pro-noprices",
    "basket-standard-pro-comparison",
];
/** Report template which is initially selected in the GUI. If undefined the first available template will be used. */
export const REPORT_DEFAULT_TEMPLATE = undefined;
/** level of the calculation line which contains the net value (depends on the used pricing procedure) */
export const NETVALUE_CALCLINE_LEVEL = 500;
/** Column which should be used for the item image */
export const COLUMN_ITEM_IMAGE = "cc080d73-88f4-4bfc-8ec1-e7e2ad30739a";
/** Column which should be used for the external item number */
export const COLUMN_EXT_ITEM_NUMBER = "c962203c-7e83-4a2f-8060-acaa4a06c921";
////////
//// pCon.login configration
/** Client ID for pCon.login. Add your client id here. */
export const PCON_LOGIN_CLIENT_ID = "W-BK_Holmris_Dynamics365";
/** Scope used for pCon.login. If long term tokens are required use `create_longterm_token query_account`. */
export const PCON_LOGIN_SCOPE = "query_account";
export const API_PREFIX = "https://app-webapi-hff-";
export const API_SUFFIX = ".azurewebsites.net/api/";
